import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../mobileMenu";
import LightWrapper from "../../wrapper/lightWrapper";
import styles from "./style.module.scss";
import Logo from "../../../assets/logo.png";
import { ChevronDown } from "react-bootstrap-icons";

const Header = (props) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  return (
    <header
      className={`containerWidth ${styles.headerContainer} ${
        props.static && styles.staticHeader
      }`}
    >
      <div className={styles.headerInner}>
        <div className={styles.sitelogo}>
          <Link to={"/"}>
            <img src={Logo} alt="SpendTheBits" />
          </Link>
        </div>
        <div className={styles.navContainer}>
          <LightWrapper className={mobileMenu && styles.blackBg}>
            <ul className={styles.desktopHeader}>
              <li>
                <a href="/#products">Product & Services</a>
              </li>
              <li>
                <a href="/#">Our Products</a> <ChevronDown size={15} />
                <ul className={styles.submenu}>
                  <li>
                    <a href="https://stbcash.com/">STB Cash App</a>
                  </li>
                  {/* <li>
                    <a href="/#contact">STB Merchant Portal</a>
                  </li> */}
                </ul>
              </li>
              <li>
                <Link to={"/faq"}>Know How</Link>
              </li>

              <li>
                <a href="/#about">About Us</a>
              </li>
              <li>
                <a href="/#contact">Contact us</a>
              </li>
              <li>
                <Link to={"/help"}>Help</Link>
              </li>
            </ul>
            <MobileMenu onChange={setMobileMenu} />
          </LightWrapper>
        </div>
      </div>
    </header>
  );
};

export default Header;
