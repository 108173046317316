import React from "react";
import styles from "./style.module.scss";
import { useParams } from "react-router-dom";
import { helpArticleData } from "../../utils/helpArticleData";

const HelpArticle = () => {
  const { sectionName, name } = useParams();
  const section = helpArticleData.find(
    (data) => data.sectionName === sectionName
  );

  function formatText(text) {
    // Replace hyphens with spaces and capitalize each word
    return text
      .replace(/-/g, " ") // Replace all hyphens with spaces
      .replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize each word
  }

  const article = section.articles.find((article) => article.name === name);
  console.log(section, article, "acjbakdjbscsdc");

  return (
    <>
      <div className={`containerWidth ${styles.helpSectionHeader}`}>
        <div>
          <h2>{formatText(article.name)}</h2>
        </div>
      </div>
      <div className={`containerWidth ${styles.helpContainer}`}>
        {article.content}
      </div>
    </>
  );
};

export default HelpArticle;
