import React, { useState } from "react";
import styles from "./style.module.scss";
import BurgerMenuIcon from "../../burgerMenuIcon";
import LightWrapper from "../../wrapper/lightWrapper";

const MobileMenu = (props) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  //   console.log(displayMenu);
  return (
    <div className={styles.menuBlock}>
      <BurgerMenuIcon
        onChange={(e) => {
          setDisplayMenu(e.target.checked);
          props.onChange(e.target.checked);
        }}
        displayMenu={displayMenu}
      />
      {displayMenu && (
        <LightWrapper className={styles.menuContainer}>
          <ul>
            <li
              onClick={() => {
                setDisplayMenu(false);
              }}
            >
              <a href="/#products">Product & Services</a>
            </li>
            <li>
              <a href="https://stbcash.com/">STB Cash App</a>
            </li>
            <li
              onClick={() => {
                setDisplayMenu(false);
              }}
            >
              <a href="/faq">Know How</a>
            </li>

            <li
              onClick={() => {
                setDisplayMenu(false);
              }}
            >
              <a href="/#about">About Us</a>
            </li>
            <li
              onClick={() => {
                setDisplayMenu(false);
              }}
            >
              <a href="/#contact">Contact us</a>
            </li>
            <li
              onClick={() => {
                setDisplayMenu(false);
              }}
            >
              <a href="/help">Help</a>
            </li>
          </ul>
        </LightWrapper>
      )}
    </div>
  );
};

export default MobileMenu;
