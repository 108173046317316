import React from "react";
import styles from "./style.module.scss";

const HelpCard = (props) => {
  return (
    <a
      href={`/help/section/${props.sectionName}`}
      className={`${styles.helpBox}`}
    >
      <h3 className={styles.helpBoxTitle}>{props.title}</h3>
      <h6 className={styles.helpBoxDescription}>{props.description}</h6>
    </a>
  );
};

export default HelpCard;
