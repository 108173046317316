import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { Header, Footer } from "./components";
import {
  Home,
  FeaturePage,
  PrivacyPolicy,
  TermsCondition,
  Faq,
  CashTermsCondition,
  CashPrivacyPolicy,
} from "./pages";
import Help from "./pages/help";
import HelpSection from "./pages/helpSection";
import HelpArticle from "./pages/helpArticle";

function App() {
  const location = useLocation();

  return (
    <div className="pageContainer">
      <Header static={location.pathname === "/" ? true : false} onClick />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/feature" element={<FeaturePage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/help" element={<Help />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route
            path="/stb-cash-terms-condition"
            element={<CashTermsCondition />}
          />
          <Route
            path="/stb-cash-privacy-policy"
            element={<CashPrivacyPolicy />}
          />
          <Route path="/help/section/:sectionName" element={<HelpSection />} />
          <Route
            path="/help/article/:sectionName/:name"
            element={<HelpArticle />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
