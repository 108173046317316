export const helpCardData = [
  {
    title: "General",
    description: "General support for common FAQs, errors, and fees.",
    sectionName: "general",
    urls: [
      {
        title: "Fees",
        url: "/help/article/general/fees",
      },
      {
        title: "Unhandled Error",
        url: "/help/article/general/unhandled-error",
      },
      {
        title: "General FAQ",
        url: "/help/article/general/general-faq",
      },
    ],
  },
  {
    title: "Identity Verification",
    description: "Support for verifying identity and KYC processes.",
    sectionName: "identity-verification",
    urls: [
      {
        title: "KYC Status Changed",
        url: "/help/article/identity-verification/kyc-status-changed",
      },
      {
        title: "KYC Durging Onboarding",
        url: "/help/article/identity-verification/kyc-during-onboarding",
      },
    ],
  },
  {
    title: "Account Management",
    description:
      "Guidance on managing balances, deposits, withdrawals, and updating account details.",
    sectionName: "account-management",
    urls: [
      {
        title: "Balances",
        url: "/help/article/account-management/balances",
      },
      {
        title: "Update Contact Details",
        url: "/help/article/account-management/update-contact-details",
      },
      {
        title: "Blocked Account",
        url: "/help/article/account-management/blocked-account",
      },
      {
        title: "Account/Wallet",
        url: "/help/article/account-management/account-wallet",
      },
    ],
  },
  {
    title: "Card Management",
    description:
      "Guides on creating, managing, and blocking cards, including handling lost or stolen cards.",
    sectionName: "card-management",
    urls: [
      {
        title: "Cancel Card",
        url: "/help/article/card-management/cancel-card",
      },
      {
        title: "Stolen Card",
        url: "/help/article/card-management/stolen-card",
      },
      {
        title: "Creating a Card",
        url: "/help/article/card-management/creating-card",
      },
    ],
  },
  {
    title: "Transactions",
    description:
      "Help for resolving transaction declines and managing recurring payments.",
    sectionName: "transactions",
    urls: [
      {
        title: "Managing Subscriptions",
        url: "/help/article/transactions/managing-subscriptions",
      },
      {
        title: "Transaction Declined",
        url: "/help/article/transactions/transaction-declined",
      },
    ],
  },
  {
    title: "Privacy/Security",
    description:
      "Help for dealing with compromised security, phishing attempts, or privacy issues.",
    sectionName: "privacy-security",
    urls: [
      {
        title: "Phishing or Scam",
        url: "/help/article/privacy-security/phishing-or-scam",
      },
      {
        title: "Compromised Private Keys",
        url: "/help/article/privacy-security/compromised-private-keys",
      },
    ],
  },
  {
    title: "Google Pay",
    description:
      "Cardholder support for using and managing the Google Pay wallet.",
    sectionName: "google-pay",
    urls: [
      { title: "Google Pay", url: "/help/article/google-pay/google-pay-art" },
    ],
  },
  {
    title: "Apple Pay",
    description:
      "Cardholder support for using and managing the Apple Pay wallet.",
    sectionName: "apple-pay",
    urls: [
      {
        title: "How do you assist a Cardholder with Apple Pay?",
        url: "/help/article/apple-pay/apple-pay-art",
      },
    ],
  },
  {
    title: "Fraud/Disputes",
    description:
      "Guidance on handling fraud detection, payment disputes, and unauthorized transactions or privacy concerns.",
    sectionName: "fraud-disputes",
    urls: [
      {
        title: "Partner identified fraud",
        url: "/help/article/fraud-disputes/partner-identified-fraud",
      },
      {
        title: "Privacy/data breach",
        url: "/help/article/fraud-disputes/privacy-data-breach",
      },
      {
        title: "Stolen identity",
        url: "/help/article/fraud-disputes/stolen-identity",
      },
      {
        title: "Unauthorized Payment Alert",
        url: "/help/article/fraud-disputes/unauthorized-payment-alert",
      },
      {
        title: "Transaction Dispute or Chargeback",
        url: "/help/article/fraud-disputes/transaction-dispute-or-chargeback",
      },
    ],
  },
];
