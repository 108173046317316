import React, { useState } from "react";
import { CaretDown, CaretRight } from "react-bootstrap-icons";
import styles from "./style.module.scss";

const FaqBox = (props) => {
  const [activeFaq, setActiveFaq] = useState(false);

  return (
    <div className={styles.faqBox}>
      <div
        className={styles.faqTitle}
        onClick={() => {
          if (activeFaq) {
            setActiveFaq(false);
          } else {
            setActiveFaq(true);
          }
        }}
      >
        <div>
          <h3>{props.question}</h3>
        </div>
        <div>
          {activeFaq ? (
            <CaretRight color="var(--whiteColor)" size={30} />
          ) : (
            <CaretDown color="var(--whiteColor)" size={30} />
          )}
        </div>
      </div>
      {activeFaq && (
        <div className={styles.faqContent}>
          <p>{props.answer}</p>
        </div>
      )}
    </div>
  );
};

export default FaqBox;
