import React from "react";
import styles from "./style.module.scss";
import HelpCard from "../../components/helpCard";
import { helpCardData } from "../../utils/helpCardData";

const Help = () => {
  const totalCount = helpCardData.length;
  return (
    <>
      <div className={styles.helpPageHeader}>
        <h2>Help</h2>
      </div>
      <div className={`containerWidth ${styles.helpContainer}`}>
        <div className={styles.helpInner}>
          {helpCardData.map((data, i) => {
            return (
              <HelpCard
                title={data.title}
                description={data.description}
                key={i}
                index={i}
                totalCount={totalCount} // Pass the total count
                sectionName={data.sectionName}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Help;
