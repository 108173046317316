import React from "react";
import styles from "./style.module.scss";
import { helpCardData } from "../../utils/helpCardData";
import { useParams } from "react-router-dom";

const HelpSection = () => {
  const { sectionName } = useParams();
  const filteredData = helpCardData.find(
    (data) => data.sectionName === sectionName
  );

  return (
    <>
      <div className={`containerWidth ${styles.helpSectionHeader}`}>
        <div>
          <h2>{filteredData.title}</h2>
          <p>{filteredData.description}</p>
        </div>
      </div>
      <div className={`containerWidth ${styles.helpContainer}`}>
        <div className={styles.links}>
          {filteredData.urls.map((data, i) => (
            <a href={data.url} className={`${styles.helpBox}`} key={i}>
              <h3 className={styles.helpBoxTitle}>{data.title}</h3>
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default HelpSection;
