export const helpArticleData = [
  {
    sectionName: "general",
    articles: [
      {
        name: "fees",
        content: (
          <div style={{ color: "white" }}>
            <h1
              style={{
                color: "white",
                marginBottom: "10px",
              }}
            >
              How to Help a Cardholder with Fee-Related Issues
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What types of fees might a cardholder encounter?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Cardholders may encounter various fees, including:
            </p>

            <ul
              style={{
                listStyleType: "disc",
                marginLeft: "20px",
                marginBottom: "20px",
              }}
            >
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Transaction fees (e.g., for purchases, withdrawals, or currency
                conversion).
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Fees related to cryptocurrency transactions (e.g., network or
                gas fees).
              </li>
            </ul>

            <h3
              style={{
                color: "white",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              How can a cardholder review fees applied to their account?
            </h3>

            <p
              style={{ marginBottom: "10px", fontSize: "15px", color: "white" }}
            >
              It's important to be transparent with cardholders about any fees
              associated with their account. Ensure they are aware of how to
              review their fees by:
            </p>

            <ul
              style={{
                listStyleType: "disc",
                marginLeft: "20px",
                marginBottom: "20px",
              }}
            >
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Checking their transaction history: Instruct cardholders to
                review their account statement or transaction history through
                your app or portal to identify any fees.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Providing access to a fee schedule: Make sure the cardholder has
                easy access to the full fee schedule outlined in your service
                terms and conditions, so they can clearly understand what fees
                apply and when.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if they believe a fee was charged
              incorrectly?
            </h3>

            <p
              style={{ marginBottom: "10px", fontSize: "15px", color: "white" }}
            >
              If a cardholder believes they were charged a fee in error, advise
              them to:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Contact your support team: The cardholder should reach out with
                details about the transaction and the fee in question. Be
                transparent about the nature of the fee and review it with the
                cardholder.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Provide transaction details: Ask the cardholder to share the
                transaction date, amount, and the nature of the fee so that your
                team can investigate the charge and determine if it was applied
                correctly.
              </li>
            </ul>
          </div>
        ),
      },
      {
        name: "unhandled-error",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder with an Unhandled Error
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if they encounter an unhandled error?
            </h3>

            <p
              style={{
                margin: "10px 0",
                color: "white",
                fontSize: "15px",
              }}
            >
              If a cardholder encounters an unhandled error while using your
              app, guide them through these steps to troubleshoot and resolve
              the issue:
            </p>

            <ol style={{ marginLeft: "20px", marginBottom: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Refresh or restart the app:</strong> Advise the
                cardholder to refresh the page or log out and back into the app.
                If the error persists, ask them to close and restart the app
                completely.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Check for app updates:</strong> Ensure the cardholder is
                using the most recent version of the app. If not, recommend they
                update the app through their device's app store, as this may
                resolve the error.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Gather error details:</strong> Encourage the cardholder
                to collect the following information, which will help in
                troubleshooting the issue:
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    A description of what they were doing when the error
                    occurred
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Any error messages or codes displayed on the screen
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    The time and date of the error
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Screenshots or screen recordings, if possible
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Their mobile or browser type and version
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Contact your support team:</strong> If the error
                persists, advise the cardholder to reach out to your support
                team and share the information they've collected. This will
                allow your team to investigate the issue more effectively.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Escalate to Immersve if needed:</strong> If the error
                involves services provided by Immersve and cannot be resolved
                internally, escalate the issue to us with all relevant details.
                Immersve will work to identify the root cause and provide a
                solution as soon as possible.
              </li>
            </ol>
          </div>
        ),
      },
      {
        name: "general-faq",
        content: (
          <>
            <div style={{ color: "white" }}>
              <h1 style={{ color: "white", marginBottom: "10px" }}>
                General FAQ: Writing Guidelines for Partners
              </h1>

              <p
                style={{
                  marginBottom: "10px",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                As a partner, you are responsible for creating and maintaining a
                general FAQ that matches your specific app experience. This FAQ
                should cover the most common questions cardholders may have
                about using your platform. Below are examples of FAQs that you
                might want to include in your own documentation:
              </p>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                How do I create a virtual card?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Explain the steps cardholders should follow to create a virtual
                card within your app. This may include navigating to the "Card
                Management" section, completing KYC verification, and confirming
                the card creation process. Ensure that the instructions are
                clear and match your platform's interface.
              </p>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                How do I update my contact details?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                If applicable, provide instructions on how cardholders can
                update their contact information, such as phone numbers and
                email addresses, through the app.
              </p>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                How do I cancel a subscription?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Outline the process for managing and canceling subscriptions
                through your app. Make sure to mention any important steps, such
                as contacting the merchant directly to cancel and how to handle
                cases where charges continue after cancellation.
              </p>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                What should I do if my transaction is declined?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Explain the possible reasons for a declined transaction (e.g.,
                insufficient funds, card compatibility issues, or security
                blocks) and provide guidance on how cardholders can resolve
                these issues through your app or by contacting support.
              </p>
              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                How can I check my account balance?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Describe how cardholders can easily check their current and
                available balance within your app, and what they should do if
                the balance seems incorrect.
              </p>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                Why do I need to complete KYC verification?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Detail the importance of completing KYC for cardholders, and
                provide step-by-step instructions on how they can submit their
                identification documents through your app to complete the
                process. If Immersve is your KYC provider, then tell your
                Cardholder than they can get KYC support through{" "}
                <a
                  href="help.immersve.com"
                  style={{ color: "white", textDecoration: "underline" }}
                >
                  help.immersve.com
                </a>
                .
              </p>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                How do I dispute a transaction?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Walk cardholders through the steps for disputing a transaction
                through your support system, including gathering necessary
                information such as transaction dates and amounts, and then
                escalate it through to Immersve.
              </p>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                What should I do if I notice fraudulent activity on my account?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Outline immediate steps cardholders should take if they suspect
                fraud, such as cancelling their card and collecting the relevant
                information for escalating the issue to Immersve.
              </p>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                How can I reach customer support?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Provide clear instructions on how cardholders can reach customer
                support through your app, such as via chat, email, or phone.
                Make sure to include relevant contact details and links.
              </p>
              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                Creating Your FAQ
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                While the examples above provide a foundation, you should ensure
                that your FAQ is customized to align with your app’s unique
                features, design, and user flow. Keep the language consistent
                with your brand and make it as easy as possible for cardholders
                to find the help they need.
              </p>
            </div>
          </>
        ),
      },
    ],
  },
  {
    sectionName: "identity-verification",
    articles: [
      {
        name: "kyc-status-changed",
        content: (
          <>
            <div style={{ color: "white" }}>
              <h1 style={{ color: "white", marginBottom: "10px" }}>
                How to Help a Cardholder When Their KYC Status Has Been Changed
              </h1>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                Why might a cardholder's KYC status change?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                A cardholder's KYC status may change for several reasons. When
                assisting them, consider these common causes:
              </p>

              <ol style={{ marginLeft: "20px", marginBottom: "20px" }}>
                <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                  <strong>Incomplete verification:</strong> The cardholder may
                  not have submitted all the required documents for KYC
                  verification, or the submitted documents may not meet the
                  verification standards.
                </li>
                <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                  <strong>Expired or outdated documents:</strong> If the
                  cardholder's identification documents have expired or are no
                  longer valid, their KYC status may change to "pending" or
                  "incomplete."
                </li>
                <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                  <strong>Suspicious or inconsistent information:</strong> If
                  there are discrepancies or inconsistencies in the cardholder's
                  personal information, we may flag their KYC status for further
                  review or require additional verification.
                </li>
              </ol>

              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                What should a cardholder do if their KYC status has changed?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                If a cardholder's KYC status changes, guide them through the
                following steps:
              </p>

              <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                  <strong>Review the notification or message:</strong> Advise
                  the cardholder to carefully read any notifications they
                  received regarding the change in their KYC status to
                  understand what specific actions are required.
                </li>
                <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                  <strong>Submit any missing or updated documents:</strong>{" "}
                  Instruct the cardholder to upload any requested documents
                  (such as proof of address or identification) if their KYC
                  status was changed due to missing or outdated information.
                </li>
                <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                  <strong>Contact support for clarification:</strong> If the
                  cardholder is unsure why their KYC status changed, encourage
                  them to reach out to your support team for further
                  explanation.
                </li>
              </ul>
              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                How will Immersve assist with KYC status issues?
              </h3>

              <p
                style={{
                  marginBottom: "10px",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                If Immersve is responsible for managing KYC for your project,
                you can escalate the issue to us for further assistance. Once
                the issue is escalated, we will:
              </p>

              <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                  <strong>Investigate the reason for the status change:</strong>{" "}
                  We'll review the cardholder's account and submitted
                  documentation to determine why their KYC status changed.
                </li>
                <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                  <strong>Provide guidance on required actions:</strong>{" "}
                  Depending on the reason for the status change, we'll help you
                  guide the cardholder in providing any additional documentation
                  or correcting any issues with their submission.
                </li>
                <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                  <strong>Reinstate the correct KYC status:</strong> Once all
                  necessary documents are verified and any issues resolved, we
                  will update the cardholder's KYC status and restore full
                  account functionality if applicable.
                </li>
              </ul>

              <p
                style={{
                  marginBottom: "10px",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                If Immersve is not managing KYC for your project, please refer
                to your designated KYC provider for assistance with any status
                issues.
              </p>
            </div>
          </>
        ),
      },
      {
        name: "kyc-during-onboarding",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder with KYC Issues
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Why is KYC necessary for cardholders?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              KYC (Know Your Customer) is a mandatory process for verifying a
              cardholder's identity to comply with regulations and prevent
              fraud. Completing KYC is required before certain account features,
              such as creating a card can be performed.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if they encounter issues while
              completing KYC?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder experiences problems with KYC, guide them through
              the following troubleshooting steps:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Ensure document clarity and validity:</strong> If the
                cardholder's documents are blurry, expired, or do not meet the
                required standards, the KYC verification may be rejected. Advise
                the cardholder to submit clear, valid documents.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Check for submission errors:</strong> If the KYC
                submission process fails, ask the cardholder to double-check all
                required fields and ensure they've uploaded the correct
                documents.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Technical issues:</strong> If the page isn't loading or
                the submission fails, ask the cardholder to refresh the page,
                log out, or try again on a different device or browser.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Contact support:</strong> If the issue continues, advise
                the cardholder to reach out to either Immersve or your
                designated KYC provider, depending on who is handling KYC for
                your project.
              </li>
            </ol>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What are common errors that might occur during KYC?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Here are some common issues that may arise during the KYC process:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Rejected documents:</strong> If the cardholder submits
                expired or invalid documents, or the images are unclear, the KYC
                verification may fail.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Incomplete information:</strong> If the cardholder
                hasn't provided all required information (e.g., missing address
                proof or selfie), the KYC process cannot be completed.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Technical issues:</strong> System errors or network
                issues may prevent the KYC process from moving forward. Advise
                the cardholder to retry after checking their connection or
                restarting the app or portal.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Account restrictions:</strong> In some cases, the
                cardholder's account may be restricted due to regulatory
                requirements or previous KYC rejections, preventing them from
                proceeding.
              </li>
            </ol>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Why might a Cardholder's KYC be declined?
            </h3>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Blurry or unclear documents:</strong> If the submitted
                identification or proof of address is blurry, out of focus, or
                not legible, the KYC verification process may fail due to the
                inability to accurately verify the details.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Expired or invalid documents:</strong> Documents such as
                a passport or driver's license must be valid and not expired. If
                the cardholder submits an expired ID, it will be rejected.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Mismatched information:</strong> If the personal
                information (e.g., name, address, or date of birth) on the
                documents does not match the information provided in the
                account, the KYC may be declined.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Unacceptable document types:</strong> Some documents may
                not meet the KYC provider's requirements (e.g., using a gym
                membership card as proof of identity instead of an official ID
                like a passport or driver's license).
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Corrupted or incomplete file uploads:</strong> If the
                documents are not fully uploaded or the files are corrupted, the
                system may not be able to process the information, leading to a
                declined KYC.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Selfie verification failure:</strong> If the selfie
                doesn't match the photo on their ID or if the image quality is
                poor, it may lead to rejection.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Regulatory issues or restrictions:</strong> If there are
                compliance or regulatory concerns based on the cardholder's
                country or the type of account being opened, the KYC may be
                automatically declined due to jurisdictional restrictions.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Suspicious or fraudulent activity:</strong> If the KYC
                provider detects any signs of suspicious or potentially
                fraudulent behavior during the document submission process, the
                application may be flagged and declined.
              </li>
            </ol>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How long does KYC verification take?
            </h3>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Time frame:</strong> KYC verification typically takes a
                few minutes to a few business days, depending on the
                verification method and whether additional manual checks are
                required.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Notification:</strong> If using Immersve KYC,
                cardholders will be notified via email once their KYC status is
                confirmed or if additional documents are required.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How will Immersve assist with KYC issues?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If Immersve is responsible for managing KYC on behalf of your
              project:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Investigate KYC issues:</strong> If a cardholder
                encounters any problems with the KYC process, we will
                investigate the cause, whether it is related to document
                verification, technical errors, or submission problems.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Guide the cardholder through the process:</strong> We
                will provide guidance on how to resolve KYC-related issues, such
                as submitting correct documents or addressing technical errors,
                to ensure smooth completion.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Ensure regulatory compliance:</strong> Immersve will
                ensure that the KYC process meets all required regulations and
                security standards.
              </li>
            </ul>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If Immersve is your KYC partner, direct all cardholder KYC-related
              queries to{" "}
              <a
                href="help.immersve.com"
                style={{ color: "white", textDecoration: "underline" }}
              >
                help.immersve.com
              </a>
              .
            </p>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If Immersve is not responsible for managing KYC for your project:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Refer to your KYC provider:</strong> Ensure that your
                internal team or designated KYC provider handles cardholder
                inquiries related to KYC verification, document submission, and
                any errors they may encounter.
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    sectionName: "account-management",
    articles: [
      {
        name: "balances",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder with Balance Issues
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What are common issues cardholders might experience with their
              balances?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Cardholders may encounter several balance-related issues, such as:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Incorrect balance displayed:</strong> The balance shown
                in their account does not reflect the correct amount.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Unexpected deductions or additions:</strong> Missing or
                unexplained funds appear in their account.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Delays in balance updates:</strong> Recent transactions
                are not reflected in their balance immediately.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  Discrepancies between available and current balance:
                </strong>{" "}
                Holds or pending transactions cause a difference between the
                available and current balance.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How can a cardholder check and verify their account balance?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              To assist a cardholder in verifying their balance:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Review transaction history:</strong> Advise them to
                check their recent transactions through your app or online
                portal to identify any unexpected activity.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Check for pending transactions:</strong> Explain that
                pending transactions may affect the available balance until
                fully processed.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Confirm recent deposits or withdrawals:</strong> Ensure
                the cardholder takes into account any recent transactions that
                may not have been processed yet.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Refresh their app or browser:</strong> Encourage them to
                refresh their account or log out and back in to view the most
                updated balance.
              </li>
            </ul>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if their balance appears incorrect?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If the cardholder believes their balance is incorrect, suggest the
              following steps:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Review all transactions:</strong> Ask them to review
                their transaction history, including pending ones, for any
                unfamiliar charges.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Look for holds or pre-authorizations:</strong> Inform
                them that certain merchants place holds on funds that
                temporarily reduce the available balance.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Update their app or browser:</strong> Encourage them to
                make sure they're using the latest version of the app or to try
                accessing their account from a different device.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Report the issue:</strong> If the issue persists, advise
                the cardholder to contact your support team for further
                investigation.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How will Immersve assist with balance discrepancies?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Once a balance issue is escalated to us through your team, we
              will:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Investigate the transaction history:</strong> Review the
                cardholder's account to identify any errors or unauthorized
                transactions.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Clarify any holds or pending transactions:</strong>{" "}
                Provide information on any holds or pre-authorizations affecting
                the balance.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Adjust the balance if necessary:</strong> If an error is
                found, we will correct the balance and ensure the cardholder's
                account is updated.
              </li>
            </ul>
          </div>
        ),
      },
      {
        name: "update-contact-details",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder Update Their Contact Details
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How can a cardholder update their contact details?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              To update their contact details, guide the cardholder through the
              following steps:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Log in to their account:</strong> Instruct the
                cardholder to log into their account via your app or online
                portal.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Navigate to the account settings:</strong> Direct them
                to the "Account Settings" or "Profile" section, where they can
                find options to update their contact information.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Enter new contact details:</strong> The cardholder can
                update their phone number, email address, or other contact
                information as needed.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Confirm changes:</strong> After updating the details,
                the cardholder should review and confirm the changes to ensure
                their information is saved.
              </li>
            </ol>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if they encounter issues while
              updating their contact details?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder experiences issues, advise them to follow these
              steps:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Check for system errors:</strong> If the page doesn't
                load or changes aren't saving, ask the cardholder to refresh the
                page or log out and back in.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Ensure information is valid:</strong> If the cardholder
                is entering invalid or incomplete contact details (such as an
                incorrectly formatted phone number or email), they may receive
                an error. Advise them to double-check the accuracy of the
                information they are providing.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Verify their KYC status:</strong> If the cardholder
                hasn't completed KYC or their account has issues related to
                verification, they may be restricted from updating contact
                details. Ensure that their KYC status is up to date.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Contact support:</strong> If they continue to experience
                issues, the cardholder should contact your support team for
                assistance. Ask them to provide any error messages they've
                received to help with troubleshooting.
              </li>
            </ol>
          </div>
        ),
      },
      {
        name: "blocked-account",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder with a Blocked Account
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Why might a cardholder's account be blocked?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              There are several reasons why a cardholder's account might be
              blocked. When assisting them, consider these common causes:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Suspicious activity:</strong> If unusual or suspicious
                activity is detected on the account, we may block it to protect
                the cardholder's funds and personal information.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Unverified account information:</strong> If the
                cardholder hasn't completed the required Know Your Customer
                (KYC) verification, their account could be blocked until the
                necessary information is provided.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Policy violations:</strong> The account may be blocked
                if the cardholder has violated terms of service, such as
                engaging in fraudulent transactions, misusing the account or
                triggering AML/CFT flags.
              </li>
            </ol>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if their account is blocked?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder finds their account blocked, guide them through
              the following steps:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Contact support immediately:</strong> Advise them to
                reach out to your support team to understand why the account has
                been blocked and what steps are required to resolve the issue.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Complete any pending verification:</strong> If the block
                is due to incomplete KYC or other verifications, instruct the
                cardholder to submit the necessary documents or information to
                verify their identity.
              </li>
            </ul>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How will Immersve assist with a blocked account?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Once the issue is escalated to us through your team, we will:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Investigate the cause of the block:</strong> We'll
                review the cardholder's account activity to determine the reason
                for the block, whether it is due to security concerns,
                incomplete verification, or suspicious transactions.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Guide you through the resolution process:</strong>{" "}
                Depending on the cause, we'll assist you in helping the
                cardholder complete any required steps, such as submitting
                identification documents or resetting login credentials.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Unblock the account:</strong> If the issue can be
                resolved, and the cardholder has not been blocked due to
                Anti-Money Laundering (AML) concerns, fraudulent behaviour, or
                violations of our terms of use, we will unblock the cardholder's
                account and ensure they regain access.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How can cardholders prevent their account from being blocked in
              the future?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Share these tips with your cardholders to help prevent future
              blocks on their account:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Complete all verification steps:</strong> Ensure that
                all KYC or account verification processes are completed promptly
                to avoid restrictions.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Monitor account activity:</strong> Advise cardholders to
                regularly check their transaction history for any unusual
                activity that could trigger a block.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  Use strong passwords and enable two-factor authentication
                  (2FA):
                </strong>{" "}
                Securing their account with a strong password and 2FA reduces
                the risk of unauthorized access and security-related blocks.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Follow terms of service:</strong> Ensure that
                cardholders are aware of the account's terms and conditions to
                avoid policy violations that could lead to a block.
              </li>
            </ul>
          </div>
        ),
      },
      {
        name: "account-wallet",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder with Account/Wallet Issues
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Why might a cardholder experience issues with their account or
              wallet?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Cardholders may encounter several issues with their account or
              wallet. When assisting them, consider the following common causes:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Connectivity issues with the platform:</strong>{" "}
                Sometimes, the cardholder may experience difficulties accessing
                their account due to connection problems. Advise the cardholder
                to refresh the app, check their internet connection, or try
                logging out and back in.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Outdated app or wallet version:</strong> If the
                cardholder is using an outdated version of the app or wallet, it
                may cause errors. Encourage them to update the app or wallet
                software to the latest version.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Account verification problems:</strong> If the
                cardholder has not completed required verification steps (such
                as KYC), they may face restrictions on their account. Advise
                them to check if there are any pending verifications and
                complete them.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Insufficient funds:</strong> If the cardholder's wallet
                doesn't have sufficient funds, they may not be able to complete
                transactions. Guide them to check their balance and top up their
                wallet if needed.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Locked or restricted account:</strong> Accounts may
                sometimes be locked or restricted due to security concerns or
                policy violations. Encourage the cardholder to contact your
                support team to resolve the issue and regain access to their
                account.
              </li>
            </ol>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if they cannot access their account?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If the cardholder is having trouble accessing their account,
              suggest the following steps:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Check login credentials:</strong> If you are a custodial
                solution, make sure they are using the correct email or
                password. If needed, they can reset their password using the
                "Forgot Password" option. If you are a non-custodial solution,
                make sure your user is selecting the correct wallet address when
                connecting their wallet.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Verify internet connection:</strong> Ensure the
                cardholder has a stable internet connection, as this could
                impact their ability to access their account or wallet.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Contact support:</strong> If they continue to experience
                issues, they should contact your support team for further
                assistance. Be sure to gather details about the problem to help
                troubleshoot more effectively.
              </li>
            </ul>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How will Immersve assist with account or wallet issues?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Once an issue is escalated to us through your team, we will:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Investigate account details:</strong> We will review the
                cardholder's account or wallet activity to determine the cause
                of the issue.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Resolve technical issues:</strong> If there are
                technical problems affecting the wallet or account, we will work
                to resolve them and ensure smooth access for the cardholder.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Unlock or verify accounts:</strong> If the issue is
                related to account verification or a security lock, we will
                guide you through the necessary steps to restore access.
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    sectionName: "card-management",
    articles: [
      {
        name: "cancel-card",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder with Card Declines
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What are the common reasons for card declines?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Card declines can be frustrating for cardholders. Here are some of
              the most frequent reasons why a card might be declined:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Insufficient funds:</strong> The cardholder may not have
                enough funds available in their account to cover the transaction
                amount.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Incorrect card details:</strong> The cardholder may have
                entered incorrect card information, such as the card number,
                expiry date, or CVV.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Card expired:</strong> The cardholder's card may have
                expired.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Technical issues:</strong> There may be temporary
                technical problems with the payment processor or the
                cardholder's bank.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Security flags:</strong> The transaction may have
                triggered security flags due to unusual activity or potential
                fraud.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Restricted card usage:</strong> The cardholder's card
                may have restrictions on certain types of transactions or
                merchants.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Merchant or transaction issues:</strong> The merchant
                may not accept the card type, or there may be issues with the
                transaction itself.
              </li>
            </ol>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if their card is declined?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder's card is declined, advise them to follow these
              steps:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Check their account balance:</strong> Ensure they have
                sufficient funds to cover the transaction.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Verify card details:</strong> Double-check that all card
                information is entered correctly.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Check card expiry date:</strong> Make sure the card is
                still valid.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Contact their bank:</strong> If the issue persists, the
                cardholder should contact their bank to inquire about any
                restrictions or issues with their card.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Try a different payment method:</strong> If possible,
                suggest using an alternative payment method, such as another
                card or a different payment platform.
              </li>
            </ol>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              7. How can a cardholder request a new card after cancellation?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              After cancelling, cardholders can easily request a new card by:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Going back to the card management section in the app or portal.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Selecting the option to request a new card.
              </li>
            </ul>
          </div>
        ),
      },
      {
        name: "stolen-card",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder When Their Card is Stolen
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              1. What should a cardholder do if their card is stolen?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder reports their card as stolen, you should guide
              them to take the following immediate actions:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Advise them to log into their account through your app or online
                portal.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Instruct them to navigate to the card management section and
                cancel the card to prevent unauthorized use.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              2. Can the cardholder still use their card after reporting it
              stolen?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              No, once the card is cancelled, it will be deactivated and cannot
              be used for any further transactions. Any attempted transactions
              after this point will be declined.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              3. Will the cardholder be liable for transactions made with the
              stolen card?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Typically, if the cardholder reports the card as stolen promptly,
              and can demonstrate unauthorized transactions, they will not be
              held liable for fraudulent charges. However, they should monitor
              their account for any suspicious activity and report unauthorized
              transactions immediately.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              4. How can a cardholder request a replacement card?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              After cancelling the stolen card, the cardholder can request a
              replacement card by:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Logging into their account through your app or online portal.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Going to the card management section.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Selecting the option to request a new card.
              </li>
            </ul>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              5. What happens to pending transactions?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Any transactions that were already authorized before the card was
              reported stolen will likely still be processed. However, any new
              attempts to use the stolen card will be blocked once its
              cancelled.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              6. Will cancelling the stolen card affect the cardholder's
              subscriptions?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Yes, cancelling the stolen card will stop any future subscription
              payments tied to that card. Cardholders should update their
              payment details with merchants to avoid service interruptions.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              7. What if the cardholder notices unauthorized transactions?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If unauthorized transactions have occurred, the cardholder should:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Report them to your support team immediately.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Assist them in raising a dispute through your standard process
                to recover any fraudulent charges.
              </li>
            </ul>
          </div>
        ),
      },
      {
        name: "creating-card",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder Create a Card
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How can a cardholder create a card?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              To create a card, guide the cardholder through the following
              steps:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Complete KYC verification:</strong> The cardholder must
                have completed the Know Your Customer (KYC) process. If they
                haven't, instruct them to submit the required documents through
                your app or portal before proceeding.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Navigate to the card creation section:</strong> Advise
                the cardholder to find the option to create a virtual card,
                typically under the "Card Management" or "Account" section.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Confirm and submit:</strong> After reviewing the
                details, the cardholder can confirm the creation of their
                virtual card. The card should be available for use immediately
                upon successful creation.
              </li>
            </ol>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What requirements must a cardholder meet to create a virtual card?
            </h3>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Completed KYC verification:</strong> The cardholder must
                have successfully completed the KYC process.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Verified account:</strong> The cardholder's account must
                be active and verified.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Correct personal information:</strong> The cardholder
                needs to ensure that all personal details and any relevant
                documentation are accurate and up to date.
              </li>
            </ol>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if they encounter issues while
              creating a virtual card?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder experiences any issues, guide them through these
              steps:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>KYC verification issues:</strong> Ensure the cardholder
                has completed the KYC process. If their KYC verification is
                incomplete or rejected, they will not be able to create a card.
                Advise them to recheck and resubmit the required documents if
                necessary.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Check for system errors:</strong> If there's a technical
                issue during the card creation process (such as the page not
                loading or errors during submission), ask the cardholder to
                refresh the page or log out and back in.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Partner-level troubleshooting:</strong> Before
                escalating the issue to Immersve, investigate the problem within
                your support team. This includes checking the cardholder's
                account status, and any system or technical errors on your end.
                Ensure that all standard troubleshooting steps have been
                followed.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Contact support:</strong> If the issue persists after
                your internal investigation, escalate the issue to Immersve.
                Provide any error messages the Cardholder has received, as well
                as their account details, to speed up the troubleshooting
                process.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What are common errors that might occur when creating a virtual
              card?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Here are some common errors that may occur during the card
              creation process:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>KYC not completed:</strong> The cardholder will be
                unable to proceed if their KYC verification has not been
                completed or has been rejected.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Account restrictions:</strong> If the account has been
                flagged for suspicious activity or is otherwise restricted, card
                creation may be blocked until the issue is resolved.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Technical issues:</strong> System errors or poor
                internet connection may interrupt the card creation process.
                Encourage cardholders to try again after checking their
                connection or restarting the process.
              </li>
            </ol>
          </div>
        ),
      },
    ],
  },
  {
    sectionName: "transactions",
    articles: [
      {
        name: "managing-subscriptions",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder with Subscription Payment Issues
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if they notice an unwanted
              subscription payment?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder encounters an unexpected or unwanted subscription
              charge, guide them through the following steps:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Contact the merchant directly:</strong> Advise the
                cardholder to first reach out to the merchant offering the
                subscription service. They should work with the merchant to
                cancel the subscription and request a refund for any overpaid
                amounts.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  If the subscription is already canceled but charges continue:
                </strong>{" "}
                If the cardholder has already canceled the subscription and
                continues to see charges, or if the merchant refuses to provide
                a refund, instruct the cardholder to cancel their card and
                gather the following information to submit a dispute with
                Immersve as soon as possible:
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Date of the transaction
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Transaction amount
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Reference number
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Merchant name
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Any relevant information about the subscription service,
                    such as terms and conditions, proof of cancellation and any
                    correspondence with the merchant.
                  </li>
                </ul>
              </li>
            </ol>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Once this information is provided, we will begin investigating the
              transaction. If appropriate, we will issue a refund for any
              overpaid amounts.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What tips can cardholders use to avoid unwanted subscription
              charges?
            </h3>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Set a reminder:</strong> Recommend that cardholders set
                a reminder in their phone or calendar a few days before the free
                trial period ends. This will help them cancel the subscription
                before being charged for the full service.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Regularly review their account:</strong> Recommend that
                cardholders regularly review all subscriptions appearing on
                their account and cancel unused services.
              </li>
            </ul>
          </div>
        ),
      },
      {
        name: "transaction-declined",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder When Their Transaction is Declined
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Why might a cardholder's transaction be declined?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              There are several reasons why a cardholder's transaction may be
              declined. When assisting them, check the following common causes:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  Connectivity issues between the merchant and payment
                  processor:
                </strong>{" "}
                Occasionally, there may be a connection issue between the
                merchant's platform and our system. Advise the cardholder to
                retry the transaction by re-entering their card details and
                trying again.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  Incompatibility with the merchant's payment platform:
                </strong>{" "}
                Some merchants may not accept certain card types. In this case,
                suggest the cardholder use an alternative payment method.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Security block:</strong> Transactions can be blocked due
                to security concerns. Advise the cardholder to contact your
                support team if they believe the transaction should have gone
                through. For the safety of the cardholder, we may not always be
                able to disclose the exact reason the transaction was blocked.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Insufficient funds:</strong> If the cardholder does not
                have enough funds to cover the purchase, the transaction will be
                declined. Encourage the cardholder to check their balance and
                top up their account if necessary.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Suspected Fraudulent Transaction:</strong> A transaction
                may be stopped if it appears fraudulent. The cardholder will be
                contacted to confirm if the transaction is authentic and can be
                processed.
              </li>
            </ol>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Why can't a cardholder see a declined transaction in their
              transaction history?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Declined transactions do not appear in the cardholder's
              transaction history, as the payment did not complete. If they are
              concerned about the decline, they should reach out to your support
              team for more details.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    sectionName: "privacy-security",
    articles: [
      {
        name: "phishing-or-scam",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder Handle Phishing or Scam Attempts
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if they suspect phishing or a scam?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder suspects they have encountered a phishing attempt
              or scam, guide them through the following steps:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Do not engage with the sender:</strong> Advise the
                cardholder to avoid clicking any links, downloading attachments,
                or responding to the message. Phishing attempts often come
                through email, text messages, or phone calls, claiming to be
                from legitimate sources.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Never share sensitive information:</strong> Inform
                cardholders that a legitimate service, including your team, will
                never ask them for sensitive information such as:
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Private keys
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Card details (e.g., full card number, CVV)
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Account passwords or one-time passcodes (OTPs)
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Personal identification numbers (PINs)
                  </li>
                </ul>
                If they receive any requests for this type of information, it is
                likely a phishing attempt or scam.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Verify the legitimacy of contact:</strong> If the
                cardholder is unsure whether the communication is from a
                legitimate source:
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Advise them to avoid responding to the message or call.
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Instruct them to contact your support team directly using
                    the contact details provided in your app or on your official
                    website, rather than using any links or numbers provided in
                    the suspicious message.
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Let them know that they can check the message's legitimacy
                    with your team to confirm whether it was an official
                    communication from your service.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Report the phishing attempt:</strong> Encourage the
                cardholder to report the suspicious message or call. If the
                phishing message involved your service or brand, instruct them
                to contact your support team immediately with the details.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Check account for suspicious activity:</strong> Advise
                the cardholder to log into their account and review their recent
                transaction history for any unauthorized charges or unusual
                activity. If they notice anything suspicious, they should
                immediately cancel their card.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Reset account credentials:</strong> If the cardholder
                clicked on any links or shared sensitive information, instruct
                them to reset their password and enable two-factor
                authentication (if applicable) to secure their account.
              </li>
            </ol>
          </div>
        ),
      },
      {
        name: "compromised-private-keys",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder When Their Private Keys Have Been
              Compromised
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How can a cardholder know if their private keys have been
              compromised?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Signs that a cardholder's private keys may have been compromised
              include:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Unexplained transactions involving their cryptocurrency or
                digital assets.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Unauthorized transfers or withdrawals from their wallet.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Notifications of access from unfamiliar devices or locations.
              </li>
            </ul>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If the cardholder notices any of these signs, they should take
              immediate action.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if their private keys have been
              compromised?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Guide your cardholder through these steps to protect their assets:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  Immediately transfer their assets to a secure wallet:
                </strong>{" "}
                Instruct the cardholder to move their digital assets to a new
                wallet with fresh, uncompromised private keys.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Stop using the compromised keys:</strong> Advise the
                cardholder to cease any further use of the compromised private
                keys and replace them with new ones generated from a secure
                source.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Report the issue:</strong> Encourage the cardholder to
                contact your support team, so the situation can be escalated to
                Immersve for further assistance.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What other steps should a cardholder take?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              In addition to securing their assets, the cardholder should:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Change all related passwords:</strong> Update any
                passwords associated with wallets, exchanges, or accounts that
                interact with their private keys.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Enable two-factor authentication (2FA):</strong>{" "}
                Wherever possible, encourage the use of 2FA to add an extra
                layer of security.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Monitor for suspicious activity:</strong> Advise the
                cardholder to keep an eye on their accounts and wallets for any
                unusual activity.
              </li>
            </ul>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Will the cardholder be liable for losses due to compromised
              private keys?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Explain to the cardholder that because private keys are the sole
              responsibility of the key holder, any losses due to compromised
              keys will not be recoverable. However, we will assist in securing
              their account and assets to prevent further losses.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How can a cardholder protect their private keys in the future?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Share these best practices with cardholders to safeguard their
              private keys:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Use hardware wallets:</strong> Hardware wallets provide
                a secure, offline environment to store private keys.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Keep backups in secure locations:</strong> Encourage
                cardholders to store backup copies of their private keys in
                secure, offline locations.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Avoid sharing private keys:</strong> Remind cardholders
                never to share their private keys with anyone, including family
                members, police or your staff and avoid storing them in easily
                accessible digital formats (e.g. on cloud storage).
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Can a cardholder recover compromised assets?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If the assets were transferred out of their wallet after the keys
              were compromised, recovery may be difficult. However, advise the
              cardholder to report the theft or unauthorized transaction to
              relevant exchanges and/or authorities. We will also investigate
              and provide any possible support.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    sectionName: "google-pay",
    articles: [
      {
        name: "google-pay-art",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder with Google Pay
            </h1>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              The below is the key information needed to support your cardholder
              with Google Pay. For more detailed guidance on setting up, using,
              or troubleshooting Google Pay, you can refer them to Google Wallet
              Help.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Help with adding their card to their Google Wallet
            </h3>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How do you set up Google Pay?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Before getting started the cardholder should have the following:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Their card details including the card number, CVV and expiry
                date.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                A device running Android 9 or higher
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                The phone must also be NFC enabled. To check whether a Google
                phone is enabled for NFC, opening settings. In the search
                settings bar, type "NFC" and tap to turn it on. If it can't be
                found, then the phone cannot make contactless payments.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Steps to set up Google Pay:
            </h3>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Open the Google Wallet app.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                At the bottom, tap Add to Wallet.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Tap Payment card.
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    Any cards you saved to your Google Account are shown.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Tap New credit or debit card.
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    To add a card, use your camera or tap Enter details
                    manually.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                At the bottom, tap Save and continue.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Read the Issuer Terms and tap Accept.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                If you're asked to verify your payment method, choose an option
                from the list.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Read and agree to the terms and conditions.
              </li>
            </ol>
            <h3
              style={{
                color: "white",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              Why can't the cardholder add their card to Google Wallet?
            </h3>

            <p
              style={{
                marginBottom: "10px",
                color: "white",
                fontSize: "15px",
              }}
            >
              If the cardholder is experiencing issues adding their card to
              Google Wallet, most common scenarios are:
            </p>

            <p
              style={{
                marginBottom: "10px",
                color: "white",
                fontSize: "15px",
              }}
            >
              "Couldn't finish card setup for tap to pay"
            </p>

            <p
              style={{
                marginBottom: "10px",
                color: "white",
                fontSize: "15px",
              }}
            >
              Something has gone wrong during the setup of the card. The
              cardholder can try again later.
            </p>

            <p
              style={{
                marginBottom: "10px",
                color: "white",
                fontSize: "15px",
              }}
            >
              "This phone can't be set up to tap to pay"
            </p>

            <p
              style={{
                marginBottom: "10px",
                color: "white",
                fontSize: "15px",
              }}
            >
              The cardholder may receive this message for one the following
              reasons:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                The phone doesn't have NFC technology: To make contactless
                payments, NFC is required. Advise the cardholder to check their
                phone supports NFC.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                The phone doesn't meet the security standards for contactless
                payments: This may occur if the phone has certain technical
                modifications.
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    <strong>Rooted device:</strong> This means the phone's
                    default software has been altered, giving the user access to
                    parts of the system that are normally restricted. This can
                    weaken security and cause issues with certain apps,
                    including Google Pay.
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    <strong>Custom ROM:</strong> A custom ROM is a version of
                    the phone's operating system that has been modified from its
                    original version. Using a custom ROM may prevent the phone
                    from meeting the security requirements for Google Pay.
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    <strong>Unlocked bootloader:</strong> The bootloader is the
                    program that runs when the phone is powered on. If it's
                    "unlocked," it means the phone can run custom software,
                    which may reduce security. Google Pay may not work on phones
                    with unlocked bootloaders.
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    <strong>Uncertified software:</strong> This refers to phone
                    software that hasn't been approved by the manufacturer or
                    Google. If the phone is running uncertified software, Google
                    Pay may not allow contactless payments due to potential
                    security risks.
                  </li>
                </ul>
              </li>
            </ul>

            <p
              style={{
                marginBottom: "10px",
                color: "white",
                fontSize: "15px",
              }}
            >
              If the card can't be set up for tap to pay, but it's saved to your
              Google Account, it can still used to:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Purchase Google products and services.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Pay on an app or website if it offers Google Pay as a payment
                method or has a "Buy with Gpay" button at checkout.
              </li>
            </ul>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What happens if a cardholder doesn't receive their verification
              code?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If they did not receive the OTP (one-time password), it is likely
              that the mobile phone number or email address held by Immersve is
              incorrect. Confirm the cardholders email and phone number held in
              your records are correct and update as required. Once Immersve
              receive the updated details, the cardholder can try again.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Help with managing their cards in the Google Wallet
            </h3>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What happens if the cardholder has lost their Android Device or it
              has been stolen?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Advise the cardholder they can:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Find, lock, or erase the device remotely using Find My Device.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Cancel their cards using your app or portal.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Help with using Google Pay
            </h3>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What is the cost to set up Google Pay?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              There is no cost to set up and use Google Pay. The usual
              transaction fees and currency conversion fees may apply.
            </p>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Is Google Pay secure?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Yes, Google Pay is secure.
            </p>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Google Pay protects payment information with multiple layers of
              security, using advanced security infrastructures to help keep the
              account safe.
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                The Google Wallet app doesn't store the physical number of the
                card, instead assigning it a unique virtual account number.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                At check out, Google Pay shares the virtual account number
                assigned to the card with the retailer. The retailer uses this
                number to get the payment. The card number isn't shared with the
                retailer.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Google Pay uses near-field communication (NFC) to send payment
                information to the retailer. NFC only works within a few inches
                of another device. This way, the card information can't be
                stolen.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Cardholders should not enable others, including family members,
                to have their fingerprint registered on their device, or share
                their passcode, while they have a card registered in Google Pay.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Cardholders are recommended to set up "find my device" so they
                can find, secure, or erase it remotely if it is lost or stolen.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How do cardholders make payments using Google Pay?
            </h3>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>In-store payments:</strong> Cardholders can simply
                unlock their phone and hold it near the payment terminal until a
                blue check mark appears. They do not need to open the Google
                Wallet app if using their default card.
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    <strong>Tip:</strong> For smaller transactions, the
                    cardholder may not need to unlock their phone, depending on
                    the country or region.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Online or in-app payments:</strong> Cardholders can use
                Google Pay by selecting the Google Pay button at checkout and
                following the instructions to proceed with the order.
              </li>
            </ul>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Can Cardholders add more than one card to Google Pay?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              There are no restrictions on the number of cards a cardholder can
              add to the Google Wallet.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Where can the Cardholder see their Google Pay transactions?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              All the Google Pay transactions will show in the cardholders
              wallet. To see transactions, simply click on the card within the
              Google Wallet and the transactions made with that card will be
              displayed.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    sectionName: "apple-pay",
    articles: [
      {
        name: "apple-pay-art",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How do you assist a Cardholder with Apple Pay®?
            </h1>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              2 months ago - Updated
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Help with adding their card to their Apple Wallet
            </h3>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How do you set up Apple Pay?
            </h3>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                The cardholder needs to know:
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    their card details including the card number, CVV and expiry
                    date.
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    the iOS Device is compatible with Apple Pay. They can check
                    compatibility here.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Open the Wallet app on the device and tap the "+" sign, then
                follow the prompts to add the card.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Read and agree to the terms and conditions
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                For security purposes, the cardholder may be asked to verify
                with a one-time-passcode (OTP).
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    They can choose to receive this code via text or email.
                  </li>
                </ul>
              </li>
            </ol>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              For more detailed troubleshooting, refer to the Apple Pay help
              centre
            </p>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Why can't the Cardholder add their card to Apple Wallet?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              You can assist the cardholder by guiding them to check the
              following:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Ensure Apple Pay can be used on their device:</strong>
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    The device is running the latest version of iOS, watchOS, or
                    macOS.
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    The device has Face ID, Touch ID, or a passcode set for
                    security.
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    The device is compatible with Apple Pay.
                  </li>
                </ul>
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  Check for any service outages or connection issues:
                </strong>{" "}
                Verify that there are no service disruptions that might be
                affecting Apple Pay functionality.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  Check if the cardholder has made changes to their Apple ID:
                </strong>{" "}
                e.g. password, phone number and/or email address. For security
                and anti-fraud purposes a cardholder will be unable to add their
                card to their Apple Wallet if their Apple ID details have been
                recently changed.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  Check the cardholder has not reached the maximum number of
                  cards on their device:
                </strong>{" "}
                the following maximums apply –
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    iOS 14:
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    iOS 15:
                    <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                      <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                        iPhone 6s and 7: 8
                      </li>
                      <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                        iPhone 8: 12
                      </li>
                      <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                        iPhone XS and later: 16
                      </li>
                    </ul>
                  </li>
                  <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                    iOS 17: no restriction
                  </li>
                </ul>
              </li>
            </ol>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If the cardholder is receiving one of the following messages,
              please contact Immerse for assistance.
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Could Not Add Card
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Invalid Card
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Card Device Limit
              </li>
            </ul>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              We will investigate and advise on the next steps to assist the
              cardholder to add their card.
            </p>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What happens if the cardholder does not receive the verification
              code?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If they did not receive the OTP (one-time password), it is likely
              that the mobile phone number or email address held by Immersve is
              incorrect. Confirm the cardholders email and/or phone number held
              in your records are up to date and correct and update as required.
              Once done, advise Immersve of the updated details for the
              cardholder email and/or phone number by using the general enquiry
              form. The cardholder can try again after Immersve have confirmed
              the contact details are updated.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Help with managing cards in the Apple Wallet
            </h3>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What happens if the cardholder has lost their Apple Device or it
              has been stolen?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              The cardholder has two options:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                If they have Find My iPhone enabled, they can use this to
                suspend or permanently remove the ability to pay with Apple Pay
                from the lost device OR
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                The cardholder can remove their cards through their Apple ID
                account page. Advise them to sign in and choose the lost device.
                Go to the Apple Pay section and click on either Remove or Remove
                all.
              </li>
            </ol>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Advise the cardholder that, even if the lost or stolen device is
              offline or not connected to WiFi or a cellular network the card(s)
              will be suspended or removed from Apple Pay.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Help with using Apple Pay
            </h3>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What is the cost to set up Apple Pay?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              There is no cost to set up and use Apple Pay. The usual
              transaction fees and currency conversion fees may apply.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How secure is Apple Pay?
            </h3>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Every transaction on an Apple device requires the cardholder to
                authenticate themselves with either Face ID, Touch ID or device
                passcode.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Each time the cardholder makes a purchase, Apple Pay uses a
                number specific to the cardholders device and unique transaction
                reference. The full card number is never stored on your Apple
                Device and it can't be shared with merchants.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Cardholders should not enable others, including family members,
                to have their fingerprint registered on their device, or share
                their passcode, while they have a card registered in Apple Pay.
              </li>
            </ul>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How do you make payments using Apple Pay?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              <strong>In-store:</strong>
            </p>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              To pay in-store, the cardholder should hold their phone over the
              payment terminal and follow the prompts to authenticate using Face
              ID, Touch ID, or their passcode. By default, Apple Pay will use
              the card set as the default. However, if the cardholder wants to
              use a different card:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Double-click the side button (or home button on older models).
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Swipe to select the preferred card.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Hold the phone near the terminal to complete the transaction.
              </li>
            </ul>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              The cardholder generally won't need to unlock their phone to pay.
              An internet connection is not required for in-store payments with
              Apple Pay.
            </p>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              <strong>Online or in-app:</strong>
            </p>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              For online or in-app purchases, the cardholder should look for the
              Apple Pay icon at checkout. They can select the card they wish to
              use and authenticate using Touch ID or Face ID on supported
              devices.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How many cards can be added to Apple Pay?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              There are no restrictions on the number of cards a cardholder can
              add to Apple Pay
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Where can the cardholder see their Apple Pay transactions?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              All the Apple Pay transaction will show in the cardholders wallet.
              To see transactions, simply click on the card within the Apple
              Wallet and the transactions made with that card will be displayed.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    sectionName: "fraud-disputes",
    articles: [
      {
        name: "partner-identified-fraud",
        content: (
          <div style={{ color: "white" }}>
            <h3 style={{ color: "white", fontWeight: "bold" }}>
              What if I suspect a cardholder's transaction is fraudulent?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Not all unfamiliar transactions are fraudulent. Before initiating
              an investigation, check if the transaction could fall into one of
              the following categories:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Unfamiliar business names:</strong> Sometimes,
                transactions appear under the merchant's trading name, which may
                not be familiar to the cardholder. Search the merchant name
                online to see if it can be recognized.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Pending or pre-authorized payments:</strong> Certain
                transactions, such as those at petrol stations, might show a
                pre-authorized amount that's higher than the actual spend. The
                pre-authorized amount will be refunded once the correct
                transaction is processed.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Offline payments:</strong> Payments made while offline,
                such as during a flight, may show up on the cardholder's account
                at a later date. These delays can sometimes cause confusion.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Subscriptions:</strong> After a free trial period, the
                cardholder may be charged for ongoing subscription membership.
                If this is the case, advise them to contact the merchant to
                request a refund and cancel the service. Direct them to more
                information on managing subscriptions.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What to do next
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If you are confident the transaction is fraudulent after these
              checks, notify the cardholder and block the card then contact us
              immediately. Provide us with the transaction details, and we will
              and investigate further.
            </p>
          </div>
        ),
      },
      {
        name: "privacy-data-breach",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder in the Event of a Privacy or Data Breach
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              1. What is a privacy or data breach?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              A privacy or data breach occurs when unauthorized individuals
              access a cardholder's personal or financial information. This
              could include card numbers, account details, or other sensitive
              data being exposed or misused.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              2. How can a cardholder know if they've been affected by a data
              breach?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder's data has been compromised, they may experience:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Unexplained transactions or account changes.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Unusual activity, such as unfamiliar login attempts or password
                reset notifications.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Notifications from merchants or service providers that their
                data has been exposed.
              </li>
            </ul>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If they suspect a breach, advise them to report it to you
              immediately.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              3. What should a cardholder do if they suspect their data has been
              breached?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Guide your cardholders through the following steps if they believe
              their personal information has been compromised:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Report the breach:</strong> Instruct the cardholder to
                contact your support team right away. Your team should then
                escalate this to us for immediate action.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Change passwords:</strong> Encourage the cardholder to
                update their passwords for online accounts, especially their
                financial, email, and any other critical accounts.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Monitor their account:</strong> Assist the cardholder in
                reviewing their recent transactions and account activity to
                identify any unauthorized use of their card or information.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              4. How will Immersve help in the event of a breach?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Once a data breach is reported to us through your team, we will:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Investigate the breach and secure any exposed cardholder data.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Provide guidance on any specific steps needed to protect the
                cardholder's account.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                If necessary, suspend or cancel their card to prevent further
                unauthorized use
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              5. Should the cardholder contact other institutions?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Yes, recommend that the cardholder notify their other financial
              institutions, especially their banks and credit card companies, to
              ensure no additional accounts have been compromised. They may also
              want to consider placing fraud alerts with credit bureaus.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              6. Will the cardholder be liable for fraudulent transactions
              caused by a breach?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Assure the cardholder that, if they report the breach promptly,
              they will not be liable for any fraudulent charges. We will work
              with you to reverse any unauthorized transactions and ensure their
              accounts are secure.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              7. How can a cardholder protect their data in the future?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Offer the following advice to your cardholders to reduce the risk
              of future data breaches:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Use strong, unique passwords for each account, especially for
                financial and email accounts.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Enable two-factor authentication (2FA) wherever possible to add
                an extra layer of security.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Be cautious of phishing scams and avoid clicking on suspicious
                links or sharing personal information through untrusted sources.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              8. Can a cardholder get a new card after a breach?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If the cardholder's data has been compromised and they need a new
              card, assist them in requesting a replacement through your app or
              portal.
            </p>
          </div>
        ),
      },
      {
        name: "stolen-identity",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder Who Suspects Stolen Identity
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What is identity theft?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Identity theft happens when someone uses a cardholder's personal
              or financial information without permission to commit fraud. This
              could involve using their name, card details, or other personal
              information for unauthorized purchases or opening credit accounts.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How can a cardholder know if their identity has been stolen?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder suspects their identity has been stolen, here are
              the signs you can help them look for:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Unfamiliar transactions on their account.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Notifications or bills for accounts or services they didn't
                open.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Being unexpectedly denied for credit.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Receiving goods or services they didn't order.
              </li>
            </ul>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Encourage them to report any of these signs to you immediately.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              What should a cardholder do if they suspect their identity has
              been stolen?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Guide your cardholder to take these steps as soon as they suspect
              identity theft:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Report the issue:</strong> Direct the cardholder to
                contact your support team to report suspected identity theft.
                Your team can then escalate this to us for further action.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Suspend the card:</strong> Assist the cardholder in
                blocking or suspending their card through the app or portal to
                prevent further unauthorized activity.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Change passwords:</strong> Advise the cardholder to
                change the passwords for their related accounts, especially
                financial and email accounts, to prevent further access.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Review transactions:</strong> Help them review recent
                transactions and identify any unauthorized or suspicious
                activity.
              </li>
            </ul>
            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Should the cardholder contact other institutions?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Yes, advise your cardholder to also:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Notify their bank or other financial institutions to make them
                aware of the suspected identity theft.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Contact national credit reporting agencies to place a fraud
                alert or freeze on their credit report, which can prevent new
                accounts from being opened in their name.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Next steps
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If the cardholder confirms there are fraudulent transactions, you
              will need to contact us immediately with the following transaction
              details:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Date of the transaction(s)
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Transaction(s) amount
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Reference number(s)
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Merchant name(s)
              </li>
            </ul>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Once this information is provided, we will block the card and
              start investigating the transaction.
            </p>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How can the cardholder protect themselves from identity theft in
              the future?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Offer the following advice to your cardholder to help protect
              their identity in the future:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Use strong, unique passwords for online accounts and change them
                regularly.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Avoid sharing personal or financial information through
                suspicious emails or links (phishing).
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Regularly monitor their account and credit report for any
                unusual activity.
              </li>
            </ul>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How can a cardholder request a new card after reporting identity
              theft?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Once the investigation is complete and the cardholder's account is
              secure, you can assist them in requesting a new card through your
              app or online portal.
            </p>
          </div>
        ),
      },
      {
        name: "unauthorized-payment-alert",
        content: (
          <div style={{ color: "white" }}>
            <h3 style={{ color: "white", fontWeight: "bold" }}>
              What if a cardholder receives an authorization code but did not
              initiate a transaction?
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              When making online purchases, cardholders may be required to
              authenticate their payment by entering a one-time passcode (OTP)
              sent to their registered mobile number.
            </p>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If the cardholder did not initiate the transaction, they should
              take the following steps:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Immediately cancel the card to prevent further unauthorized
                transaction attempts. They can do this via the card management
                portal or app.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Verify with family or other authorized users whether someone
                else has mistakenly used their card.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Check their transaction history for any suspicious or
                unauthorized charges. If they find any, they should submit a
                dispute to report the fraudulent transactions.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Request a new card through the app or support portal to continue
                shopping securely.
              </li>
            </ol>
          </div>
        ),
      },
      {
        name: "transaction-dispute-or-chargeback",
        content: (
          <div style={{ color: "white" }}>
            <h1 style={{ color: "white", marginBottom: "10px" }}>
              How to Help a Cardholder with a Transaction Reversal
            </h1>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              How to assist a cardholder with a transaction reversal
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              If a cardholder asks for a transaction reversal, also known as a
              chargeback or dispute, it's important to follow the proper steps
              to request a refund from the merchant. Here's how to guide them:
            </p>

            <ol style={{ marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>Confirm the transaction is completed:</strong> Only
                completed transactions are eligible for a refund or chargeback.
                Pending transactions cannot be reversed or refunded until they
                are fully processed.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>
                  Instruct the cardholder to contact the merchant directly:
                </strong>{" "}
                The first step is for the cardholder to request a refund from
                the merchant. Often, this resolves the issue without needing
                further action.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                <strong>If the merchant refuses a refund:</strong> If the
                cardholder believes they are entitled to a refund but the
                merchant is uncooperative, then you need to get in touch with us
                and raise a dispute.
              </li>
            </ol>

            <h3
              style={{ color: "white", fontWeight: "bold", marginTop: "20px" }}
            >
              Information needed for a dispute
            </h3>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              To ensure the dispute is handled quickly and effectively, we will
              need the following details from the cardholder:
            </p>

            <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                A clear description of the situation, including what was
                purchased and the reason for requesting a refund. Encourage them
                to provide as much factual information as possible.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Any proof they can provide, such as receipts, invoices, or links
                to the product or service purchased online.
              </li>
              <li style={{ marginBottom: "8px", fontSize: "15px" }}>
                Copies of all communication between the cardholder and the
                merchant, to demonstrate the steps they took to resolve the
                issue.
              </li>
            </ul>

            <p
              style={{ marginBottom: "10px", color: "white", fontSize: "15px" }}
            >
              Once you have this information, submit it through the appropriate
              dispute channels, and we will proceed with the chargeback process.
              We will notify you of the outcome. If the chargeback is
              successful, the cardholder will receive a refund for the agreed
              amount. This may be all or part of the disputed transaction
              amount.
            </p>
          </div>
        ),
      },
    ],
  },
];
